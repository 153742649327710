import React, { useState, useEffect } from "react";
import Contact from "../components/Contact";
import { animateScroll as scroll } from "react-scroll";

const ContactPage = () => {
  return (
    <>
      <Contact />
    </>
  );
};

export default ContactPage;
