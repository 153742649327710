import React, { useState, useEffect } from "react";
import { Button } from "../ButtonElements";
import { ListenLink2 } from "../ButtonElements";
import { animateScroll as scroll } from "react-scroll";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";
const InfoSection2 = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  description9,
  description10,
  description11,
  description12,
  description13,
  buttonLabel,
  buttonLabel2,
  buttonLabel3,

  img,
  // img2,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const [setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const togglePlayer = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>
                  {description}
                  <b>
                    <i>{description2}</i>
                  </b>
                  {description3}
                  <b>
                    <i> {description4}</i>
                  </b>
                  {description5}
                  <b>
                    <i> {description6}</i>
                  </b>
                  {description7}
                  <b>
                    <i> {description8}</i>
                  </b>
                  {description9}
                  <b>
                    <i>{description10}</i>
                  </b>
                  {description11}
                  <b>
                    <i> {description12}</i>
                  </b>
                  {description13}
                </Subtitle>
                <BtnWrap>
                  <Button
                    to="producer"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    to="filmAndTV"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel2}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <ListenLink2 to="player" onClick={togglePlayer}>
                    Listen
                  </ListenLink2>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
                {/* <Img src={img2} alt={alt} /> */}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection2;
