import React from "react";
import Icon1 from "../../images/LadocetleVetoep1.jpg";
import Icon2 from "../../images/Ladocep2.jpeg";
import Icon3 from "../../images/ladoc3.jpeg";
import Icon4 from "../../images/Ladocep4.jpeg";
import Icon5 from "../../images/MeurtresenpaysdOleron.jpeg";
import Icon6 from "../../images/LesFantomesduHavre.jpg";
import Icon7 from "../../images/Hortense.jpg";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
} from "./ServicesElements";

const Services = () => {
  const openInNewTab = () => {
    const newWindow = window.open("/FilmPage1", "_self");
    if (newWindow) newWindow.opener = null;
  };
  const openInNewTab2 = () => {
    const newWindow2 = window.open("/FilmPage2", "_self");
    if (newWindow2) newWindow2.opener = null;
  };
  const openInNewTab3 = () => {
    const newWindow3 = window.open(
      "/FilmPage3",
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow3) newWindow3.opener = null;
  };
  const openInNewTab4 = () => {
    const newWindow4 = window.open(
      "/FilmPage4",
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow4) newWindow4.opener = null;
  };
  const openInNewTab5 = () => {
    const newWindow5 = window.open(
      "/FilmPage5",
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow5) newWindow5.opener = null;
  };
  const openInNewTab6 = () => {
    const newWindow6 = window.open(
      "/FilmPage6",
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow6) newWindow6.opener = null;
  };
  const openInNewTab7 = () => {
    const newWindow7 = window.open(
      "/FilmPage7",
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow7) newWindow7.opener = null;
  };

  return (
    <>
      <ServicesContainer id="filmAndTV">
        <ServicesH1>Film & TV</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon
              to="/FilmPage1"
              onClick={() => {
                openInNewTab(Icon1.link);
              }}
              src={Icon1}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab2(Icon2.link);
              }}
              src={Icon2}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab3(Icon3.link);
              }}
              src={Icon3}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab4(Icon4.link);
              }}
              src={Icon4}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab5(Icon5.link);
              }}
              src={Icon5}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab6(Icon6.link);
              }}
              src={Icon6}
            />
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon
              onClick={() => {
                openInNewTab7(Icon7.link);
              }}
              src={Icon7}
            />
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
