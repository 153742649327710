import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";

import {
  Container,
  ShowReelPageWrapper,
  ShowReelPageVideo,
} from "../ShowReel/ShowReelElements";
import {
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import {
  Foot,
  FooterBtn,
  FooterBtnLink,
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "../components/Footer/FooterElements";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "../components/Navbar/NavbarElements";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SideBtnWrap,
  SidebarLink,
  SidebarRoute,
  SidebarRouteP,
} from "../components/Sidebar/SidebarElements";
const ShowReelPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const [scrollFoot, setScrollFoot] = useState(false);
  const changeFoot = () => {
    if (window.scrollY >= 80) {
      setScrollFoot(true);
    } else {
      setScrollFoot(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav, changeFoot);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const togglePlayer = () => {
    scroll.scrollToTop();
  };
  const toggleReel = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink onClick={window.location} href="/">
              Home
            </SidebarLink>
            <SidebarRouteP to="/player" onClick={togglePlayer}>
              Listen
            </SidebarRouteP>
            <SidebarLink onClick={window.location} href="/#about">
              About
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#filmAndTV">
              Film & TV
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#composer">
              Composer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#producer">
              Producer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#musician">
              Writing
            </SidebarLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute to="/contact">Contact</SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              olly gorman
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavBtn>
                <NavBtnLink to="/player" onClick={togglePlayer}>
                  Listen
                </NavBtnLink>
              </NavBtn>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#filmAndTV"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Film & TV
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#composer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Composer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#producer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Producer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#musician"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Writing
                </NavLinks>
              </NavItem>

              <NavBtn>
                <NavBtnLink to="/contact">Contact</NavBtnLink>
              </NavBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>{" "}
      <Container>
        <ShowReelPageWrapper>
          <ShowReelPageVideo>
            <ReactPlayer
              light={false}
              controls={true}
              url={"https://vimeo.com/925094566?share=copy"}
              height={500}
              width={750}
            />
          </ShowReelPageVideo>
        </ShowReelPageWrapper>
      </Container>
      <IconContext.Provider value={{ color: "#fff", className: "contactIcon" }}>
        <Foot scrollFoot={scrollFoot}>
          <FooterContainer>
            <FooterWrap>
              <FooterLinksContainer>
                <FooterLinksWrapper>
                  <FooterLinkItems>
                    <FooterLinkTitle>About Me</FooterLinkTitle>
                    <FooterLink
                      onClick={window.location}
                      href="/#about"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      About
                    </FooterLink>
                    <FooterLink
                      onClick={window.location}
                      href="/#composer"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Composer
                    </FooterLink>
                    <FooterLink
                      onClick={window.location}
                      href="/#producer"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Producer
                    </FooterLink>
                    <FooterLink
                      onClick={window.location}
                      href="/#musician"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Writing
                    </FooterLink>
                  </FooterLinkItems>
                  <FooterLinkItems>
                    <FooterLinkTitle>Media</FooterLinkTitle>
                    <FooterBtnLink to="/player" onClick={togglePlayer}>
                      Listen
                    </FooterBtnLink>
                    <FooterBtnLink to="/ShowReel" onClick={toggleReel}>
                      Show Reel
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//www.imdb.com/name/nm9708049/"
                      target="blank"
                    >
                      Imdb
                    </FooterBtnLink>

                    <FooterLink
                      onClick={window.location}
                      href="/#filmAndTV"
                      // smooth={true}
                      // duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Film & TV
                    </FooterLink>
                  </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                  <FooterLinkItems>
                    <FooterLinkTitle>Contact</FooterLinkTitle>
                    <FooterBtn>
                      <FooterBtnLink to="/contact">Contact Form</FooterBtnLink>
                    </FooterBtn>
                    <a
                      href="mailto: ollygorman@gmail.com"
                      className="email"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Email
                    </a>
                  </FooterLinkItems>
                  <FooterLinkItems>
                    <FooterLinkTitle>Social Media</FooterLinkTitle>

                    <FooterBtnLink
                      to="//www.instagram.com/ollygorman"
                      target="blank"
                    >
                      Instagram
                    </FooterBtnLink>

                    <FooterBtnLink
                      to="//www.facebook.com/ollygorman"
                      target="blank"
                    >
                      Facebook
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//www.youtube.com/@ollygorman1645"
                      target="blank"
                    >
                      YouTube
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//mobile.twitter.com/ollygormanmusic/"
                      target="blank"
                    >
                      Twitter
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//soundcloud.com/olly-gorman"
                      target="blank"
                    >
                      SoundCloud
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//open.spotify.com/artist/4B4K1QdItS479GUXZPuZeB"
                      target="blank"
                    >
                      Spotify
                    </FooterBtnLink>
                  </FooterLinkItems>
                </FooterLinksWrapper>
              </FooterLinksContainer>
              <SocialMedia>
                <SocialMediaWrap>
                  <SocialLogo to="/" onClick={toggleHome}>
                    Olly Gorman
                  </SocialLogo>
                  <WebsiteRights>
                    Olly Gorman &copy; {new Date().getFullYear()} All Rights
                    reserved.
                  </WebsiteRights>
                  <SocialIcons>
                    <SocialIconLink
                      href="//www.facebook.com/ollygorman"
                      target="blank"
                      aria-label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//open.spotify.com/artist/4B4K1QdItS479GUXZPuZeB"
                      target="blank"
                      aria-label="Spotify"
                    >
                      <FaSpotify />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//www.instagram.com/ollygorman"
                      target="blank"
                      aria-label="Instagram"
                    >
                      <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//www.youtube.com/@ollygorman1645"
                      target="blank"
                      aria-label="YouTube"
                    >
                      <FaYoutube />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//mobile.twitter.com/ollygormanmusic/"
                      target="blank"
                      aria-label="Twitter"
                    >
                      <FaTwitter />
                    </SocialIconLink>
                  </SocialIcons>
                </SocialMediaWrap>
              </SocialMedia>
            </FooterWrap>
          </FooterContainer>
        </Foot>
      </IconContext.Provider>
    </>
  );
};

export default ShowReelPage;
