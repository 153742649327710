import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjFive } from "../components/infoSection/Data";

const FilmPage1Display = () => {
  return (
    <>
      <FilmPage {...homeObjFive} />
    </>
  );
};

export default FilmPage1Display;
