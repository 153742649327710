import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjTen } from "../components/infoSection/Data";

const FilmPage6Display = () => {
  return (
    <>
      <FilmPage {...homeObjTen} />
    </>
  );
};

export default FilmPage6Display;
