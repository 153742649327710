import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjNine } from "../components/infoSection/Data";

const FilmPage5Display = () => {
  return (
    <>
      <FilmPage {...homeObjNine} />
    </>
  );
};

export default FilmPage5Display;
