import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjEleven } from "../components/infoSection/Data";

const FilmPage7Display = () => {
  return (
    <>
      <FilmPage {...homeObjEleven} />
    </>
  );
};

export default FilmPage7Display;
