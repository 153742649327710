import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/infoSection";
import InfoSection2 from "../components/infoSection/index2";
import InfoSection3 from "../components/infoSection/index3";
import InfoSection4 from "../components/infoSection/index4";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
} from "../components/infoSection/Data";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Services from "../components/Services";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection2 {...homeObjTwo} />
      <InfoSection3 {...homeObjThree} />
      <InfoSection4 {...homeObjFour} />
      <Services />

      <Footer toggle={toggle} />
    </>
  );
};

export default Home;
