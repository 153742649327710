import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;

  justify-content: center;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  height: auto;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #9caf88;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  /* margin-bottom: px; */
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`;

export const Subtitle = styled.p`
  font-family: "Poppins";
  max-width: 440px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 20px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  position: relative;
  margin-top: -250px;
  /* overflow: hidden; */
  /* width: 100px; */
  /* height: 100px;  */
  /* border: 1px solid black; */
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  position: absolute;
  left: -1000px;

  -webkit-animation: slide 1s forwards;
  -webkit-animation-delay: 1s;
  animation: slide 1s forwards;
  animation-delay: 0.5s;
  @-webkit-keyframes slide {
    100% {
      left: 0;
    }
  }

  @keyframes slide {
    100% {
      left: 0;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
    width: 80%;
    margin-top: 200px;
    margin-bottom: 100px;
    padding-bottom: 50px;
  }
`;
