import styled from "styled-components";
// import { Link } from "react-router-dom";

export const Container = styled.div`
  width: auto;
`;

export const ShowReelPageWrapper = styled.div`
  width: auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowReelPageVideo = styled.div`
  position: center;
  @media screen and (max-width: 768px) {
    scale: 0.8;
  }
  @media screen and (max-width: 610px) {
    scale: 0.7;
  }
  @media screen and (max-width: 530px) {
    scale: 0.65;
  }
  @media screen and (max-width: 490px) {
    scale: 0.55;
  }
  @media screen and (max-width: 415px) {
    scale: 0.45;
  }
`;

export const ReactPlayer = styled.video``;
