import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import ContactPage from "./pages/Contact";
import PlayerPage from "./pages/Player";
import ShowReelPage from "./pages/ShowReel";

import FilmPage1Display from "./pages/FilmPage1Display";
import FilmPage2Display from "./pages/FilmPage2Display";
import FilmPage3Display from "./pages/FilmPage3Display";
import FilmPage4Display from "./pages/FilmPage4Display";
import FilmPage5Display from "./pages/FilmPage5Display";
import FilmPage6Display from "./pages/FilmPage6Display";
import FilmPage7Display from "./pages/FilmPage7Display";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/contact" element={<ContactPage />} exact />
        <Route path="/player" element={<PlayerPage />} exact />
        <Route path="/ShowReel" element={<ShowReelPage />} exact />
        <Route path="/FilmPage1" element={<FilmPage1Display />} exact />
        <Route path="/FilmPage2" element={<FilmPage2Display />} exact />
        <Route path="/FilmPage3" element={<FilmPage3Display />} exact />
        <Route path="/FilmPage4" element={<FilmPage4Display />} exact />
        <Route path="/FilmPage5" element={<FilmPage5Display />} exact />
        <Route path="/FilmPage6" element={<FilmPage6Display />} exact />
        <Route path="/FilmPage7" element={<FilmPage7Display />} exact />
      </Routes>
    </Router>
  );
};

export default App;
