import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjEight } from "../components/infoSection/Data";

const FilmPage4Display = () => {
  return (
    <>
      <FilmPage {...homeObjEight} />
    </>
  );
};

export default FilmPage4Display;
