import React from "react";
import PlayerPage from "../components/Player";

const Player = () => {
  return (
    <>
      <PlayerPage />
    </>
  );
};

export default Player;
