import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
export const ServicesContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;
  padding: 40px;
  @media screen and (max-width: 1280px) {
    height: 1300px;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const ServicesWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  height: auto;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 515px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const ServicesCard = styled.div`
  font-family: "Poppins";
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 533px;
  padding: 0px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  @media screen and (max-width: 1630px) {
    scale: 0.9;
  }
  @media screen and (max-width: 1280px) {
    width: 300px;
    height: 200px;
    margin-bottom: 160px;
    margin-left: -20px;
  }
  @media screen and (max-width: 650px) {
    width: 200px;
    height: 170px;
    margin-left: 20px;
  }

  @media screen and (max-width: 515px) {
    padding-bottom: 100px;
  }
`;
export const ServicesIcon = styled.img`
  height: 533px;
  width: 400px;
  margin-bottom: 10px;

  @media screen and (max-width: 1280px) {
    width: 300px;
  }

  @media screen and (max-width: 650px) {
    width: 250px;
  }
  @media screen and (max-width: 515px) {
    width: 300px;
    height: 400px;
    padding-bottom: 30px;
  }
`;

export const ServicesH1 = styled.h1`
  font-family: "Poppins";
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-family: "Poppins";
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-family: "Poppins";
  font-size: 1rem;
  text-align: center;
`;
