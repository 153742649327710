import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import "../../App.css";
import { animateScroll as scroll } from "react-scroll";
import { IconContext } from "react-icons/lib";
// import { homeObjTwo } from "../infoSection/Data";
import {
  Foot,
  FooterBtn,
  FooterBtnLink,
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = ({ toggle }) => {
  const [scrollFoot, setScrollFoot] = useState(false);
  const changeFoot = () => {
    if (window.scrollY >= 80) {
      setScrollFoot(true);
    } else {
      setScrollFoot(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeFoot);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const togglePlayer = () => {
    scroll.scrollToTop();
  };
  const toggleReel = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff", className: "contactIcon" }}>
        <Foot scrollFoot={scrollFoot}>
          <FooterContainer>
            <FooterWrap>
              <FooterLinksContainer>
                <FooterLinksWrapper>
                  <FooterLinkItems>
                    <FooterLinkTitle>About Me</FooterLinkTitle>
                    <FooterLink
                      to="about"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      About
                    </FooterLink>
                    <FooterLink
                      to="composer"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Composer
                    </FooterLink>
                    <FooterLink
                      to="producer"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Producer
                    </FooterLink>
                    <FooterLink
                      to="musician"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Writing
                    </FooterLink>
                  </FooterLinkItems>
                  <FooterLinkItems>
                    <FooterLinkTitle>Media</FooterLinkTitle>
                    <FooterBtnLink to="/player" onClick={togglePlayer}>
                      Listen
                    </FooterBtnLink>
                    <FooterBtnLink to="/ShowReel" onClick={toggleReel}>
                      Show Reel
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//www.imdb.com/name/nm9708049/"
                      target="blank"
                    >
                      Imdb
                    </FooterBtnLink>

                    <FooterLink
                      to="filmAndTV"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Film & TV
                    </FooterLink>
                  </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                  <FooterLinkItems>
                    <FooterLinkTitle>Contact</FooterLinkTitle>
                    <FooterBtn>
                      <FooterBtnLink to="/contact">Contact Form</FooterBtnLink>
                    </FooterBtn>
                    <a
                      href="mailto: ollygorman@gmail.com"
                      className="email"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Email
                    </a>
                  </FooterLinkItems>
                  <FooterLinkItems>
                    <FooterLinkTitle>Social Media</FooterLinkTitle>

                    <FooterBtnLink
                      to="//www.instagram.com/ollygorman"
                      target="blank"
                    >
                      Instagram
                    </FooterBtnLink>

                    <FooterBtnLink
                      to="//www.facebook.com/ollygorman"
                      target="blank"
                    >
                      Facebook
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//www.youtube.com/@ollygorman1645"
                      target="blank"
                    >
                      YouTube
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//mobile.twitter.com/ollygormanmusic/"
                      target="blank"
                    >
                      Twitter
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//soundcloud.com/olly-gorman"
                      target="blank"
                    >
                      SoundCloud
                    </FooterBtnLink>
                    <FooterBtnLink
                      to="//open.spotify.com/artist/4B4K1QdItS479GUXZPuZeB"
                      target="blank"
                    >
                      Spotify
                    </FooterBtnLink>
                  </FooterLinkItems>
                </FooterLinksWrapper>
              </FooterLinksContainer>
              <SocialMedia>
                <SocialMediaWrap>
                  <SocialLogo to="/" onClick={toggleHome}>
                    Olly Gorman
                  </SocialLogo>
                  <WebsiteRights>
                    Olly Gorman &copy; {new Date().getFullYear()} All Rights
                    reserved.
                  </WebsiteRights>
                  <SocialIcons>
                    <SocialIconLink
                      href="//www.facebook.com/ollygorman"
                      target="blank"
                      aria-label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//open.spotify.com/artist/4B4K1QdItS479GUXZPuZeB"
                      target="blank"
                      aria-label="Spotify"
                    >
                      <FaSpotify />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//www.instagram.com/ollygorman"
                      target="blank"
                      aria-label="Instagram"
                    >
                      <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//www.youtube.com/@ollygorman1645"
                      target="blank"
                      aria-label="YouTube"
                    >
                      <FaYoutube />
                    </SocialIconLink>
                    <SocialIconLink
                      href="//mobile.twitter.com/ollygormanmusic/"
                      target="blank"
                      aria-label="Twitter"
                    >
                      <FaTwitter />
                    </SocialIconLink>
                  </SocialIcons>
                </SocialMediaWrap>
              </SocialMedia>
            </FooterWrap>
          </FooterContainer>
        </Foot>
      </IconContext.Provider>
    </>
  );
};

export default Footer;
