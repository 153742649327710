import styled from "styled-components";
import { Link } from "react-router-dom";
export const Container = styled.div`
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c2c1a5;
  flex-direction: column;
`;
export const Carousel = styled.div`
  width: 300px;

  /* @media screen and (max-width: 500px) {
    width: 400px;
  } */
`;
export const PlayerContainerTitle = styled.div`
  /* scale: 0.9; */
  font-family: "Poppins";
  margin-top: 20px;
  margin-bottom: 20px;

  color: black;
  width: 400px;
  background-color: transparent;
  position: relative;
`;
export const PlayerContainer = styled.div`
  font-family: "Poppins";
  scale: 0.9;
  margin-top: 50px;
  margin-bottom: -30px;
  height: 800px;
  width: 500px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 510px) {
    scale: 0.9;
  }
`;
export const PlayerTitle = styled.div`
  font-family: "Poppins";
  background-color: black;
  font-size: 15px;
  width: 420px;
  padding: 10px;
  margin-bottom: -2px;
  /* position: fixed; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Raleway";
`;
