import React from "react";
import ShowReelPage from "../ShowReel";

const ShowReel = () => {
  return (
    <>
      <ShowReelPage />
    </>
  );
};

export default ShowReel;
