import React, { useState, useEffect } from "react";
import "../../App.css";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Footer from "../../components/Footer";
import { animateScroll as scroll } from "react-scroll";
import { useForm, ValidationError } from "@formspree/react";
import {
  Container,
  FormContent,
  FormInput,
  FormWrap,
  Form,
  Icon2,
  FormH1,
  FormLabel,
  FormButton,
  FormArea,
} from "./ContactElememts";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SideBtnWrap,
  SidebarLink,
  SidebarRoute,
  SidebarRouteP,
} from "../../components/Sidebar/SidebarElements";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "../../components/Navbar/NavbarElements";

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const [scrollFoot, setScrollFoot] = useState(false);
  const changeFoot = () => {
    if (window.scrollY >= 80) {
      setScrollFoot(true);
    } else {
      setScrollFoot(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav, changeFoot);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const togglePlayer = () => {
    scroll.scrollToTop();
  };
  const [state, handleSubmit] = useForm("xgedygao");
  if (state.succeeded) {
    return (
      <div className="email-response">
        <p>
          Thankyou for getting in touch! <br></br>I will get back to you as
          quickly<br></br> as possible
        </p>
        <Icon2 to="/"> Back To Site..</Icon2>
      </div>
    );
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              olly gorman
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavBtn>
                <NavBtnLink to="/player" onClick={togglePlayer}>
                  Listen
                </NavBtnLink>
              </NavBtn>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#filmAndTV"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Film & TV
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#composer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Composer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#producer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Producer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#musician"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Writing
                </NavLinks>
              </NavItem>

              <NavBtn>
                <NavBtnLink to="/contact">Contact</NavBtnLink>
              </NavBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>{" "}
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink onClick={window.location} href="/">
              Home
            </SidebarLink>
            <SidebarRouteP to="/player" onClick={togglePlayer}>
              Listen
            </SidebarRouteP>
            <SidebarLink onClick={window.location} href="/#about">
              About
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#filmAndTV">
              Film & TV
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#composer">
              Composer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#producer">
              Producer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#musician">
              Writing
            </SidebarLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute to="/contact">Contact</SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Please Contact Me Via the Form!</FormH1>

              <FormLabel htmlFor="name">Name</FormLabel>
              <FormInput type="text" id="name" name="name" />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              <FormLabel htmlFor="company">Company</FormLabel>
              <FormInput type="text" id="company" name="company" />
              <ValidationError
                prefix="Company"
                field="company"
                errors={state.errors}
              />
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <FormInput type="email" id="email" name="email" />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <FormInput type="text" id="phone" name="phone" />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
              <FormLabel htmlFor="message">Message</FormLabel>
              <FormArea type="text" id="message" name="message" />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <FormButton type="submit" disabled={state.submitting}>
                Submit
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
      <Footer />
    </>
  );
};

<></>;
export default Contact;
