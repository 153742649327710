import React from "react";
import FilmPage from "../components/FilmPage/index.js";

import { homeObjSeven } from "../components/infoSection/Data";

const FilmPage3Display = () => {
  return (
    <>
      <FilmPage {...homeObjSeven} />
    </>
  );
};

export default FilmPage3Display;
