import React, { useState, useEffect } from "react";
import { Button } from "../ButtonElements";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "../Navbar/NavbarElements";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SideBtnWrap,
  SidebarLink,
  SidebarRoute,
  SidebarRouteP,
} from "../../components/Sidebar/SidebarElements";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Heading2,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./filmPageElements";

const FilmPage = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  headline2,
  darkText,
  description,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  description9,
  buttonLabel,
  buttonLabel2,
  buttonLabel3,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              olly gorman
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavBtn>
                <NavBtnLink to="/player">Listen</NavBtnLink>
              </NavBtn>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#filmAndTV"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Film & TV
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#composer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Composer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#producer"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Producer
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  onClick={window.location}
                  href="/#musician"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Writing
                </NavLinks>
              </NavItem>

              <NavBtn>
                <NavBtnLink to="/contact">Contact</NavBtnLink>
              </NavBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>{" "}
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink onClick={window.location} href="/">
              Home
            </SidebarLink>
            <SidebarRouteP to="/player">Listen</SidebarRouteP>
            <SidebarLink onClick={window.location} href="/#about">
              About
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#filmAndTV">
              Film & TV
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#composer">
              Composer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#producer">
              Producer
            </SidebarLink>
            <SidebarLink onClick={window.location} href="/#musician">
              Writing
            </SidebarLink>
          </SidebarMenu>
          <SideBtnWrap>
            <SidebarRoute to="/contact">Contact</SidebarRoute>
          </SideBtnWrap>
        </SidebarWrapper>
      </SidebarContainer>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                <Subtitle darkText={darkText}>{description3}</Subtitle>
                <Subtitle darkText={darkText}>{description4}</Subtitle>
                <Subtitle darkText={darkText}>{description5}</Subtitle>
                <Subtitle darkText={darkText}>{description6}</Subtitle>
                <Subtitle darkText={darkText}>{description7}</Subtitle>
                <Subtitle darkText={darkText}>{description8}</Subtitle>
                <Subtitle darkText={darkText}>{description9}</Subtitle>
                <BtnWrap>
                  <Button
                    onClick={window.location}
                    href="/#filmAndTV"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="false"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={window.location}
                    href="ShowReel"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="false"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel2}
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    onClick={window.location}
                    href="player"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="false"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel3}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img id="slide" src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default FilmPage;
