// info page 1

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Olly Gorman",
  headline: "about",
  description:
    "Olly Gorman is a Liverpool based composer and multi-instrumentalist, who specialises in creating captivating music for a diverse range of mediums. With a vast repertoire spanning film, television, and concert music, Olly’s compositions are known for their unique fusion of contemporary classical elements and electronic soundscapes. Bringing together the worlds of acoustic instrumentation and modern technology, Olly seamlessly blends traditional orchestral instruments with a wide array of both modern and vintage electronic instruments and techniques. This innovative approach to composition allows him to create rich and textured soundscapes that captivate audiences across various genres.",
  buttonLabel: "Composer",
  buttonLabel2: "Listen",
  imgStart: false,
  img: require("../../images/olly-image-1.jpeg"),
  alt: "Olly",
  dark: true,
  primary: true,
  darkText: false,
};

// info page 2

export const homeObjTwo = {
  id: "composer",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Olly Gorman",
  headline: "Composer work",
  description:
    "Olly has written and recorded many scores for television and film, including all 6 episodes of the French drama",
  description2: " La Doc et le Véto (2021-2024)",
  description3:
    " starring Michel Cymès and Dounia Coesens. Prior to this, Olly scored the hit French crime drama",
  description4: " Meurtres en pays d'Oleron (2018)",
  description5:
    ", starring Michel Cymès and Hélène Seuzaret. Olly's extensive score was a combination of electronics, guitars, and a live string quartet. Other recent work includes scoring the hugely popular French film’s",
  description6: " Les Fantômes du Havre (2019)",
  description7: " starring Barbara Cabrita and Frédéric Diefenthal and",
  description8: " Hortense (2020) ",
  description9:
    " starring Catherine Jacob and Pauline Bression. This year, Olly composed the score for a brand-new French film titled ",
  description10: "Le Combat d’Alice (2024)",
  description11:
    " which was nominated at La Rochelle’s ‘Festival de la Fiction’. He also began work on a new police drama directed by Bénédicte Delmas entitled",
  description12: "Brigade Fluviale",
  description13: " set for release later this year.",
  buttonLabel: "Producer",
  buttonLabel2: "Film & TV",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/LadocetleVetoep1.jpg"),
  // img2: require("../../images/bannersImage.jpg"),
  alt: "Olly",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "producer",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Olly Gorman",
  headline: "Producer work",
  description:
    "Outside of TV and film Olly has worked very closely with the Indie/Pop Star",
  description2: " Banners",
  description3: ". In 2023 he co-wrote the songs ",
  description4: "Have you ever loved Someone ",
  description5: "and ",
  description6: "Miles Away",
  description7:
    ". Which were released by Banners to critical acclaim and huge commercial success. Olly also co-prodcued Banners most recent single ",
  description8: "Perfectly Broken - Duet Version",
  description9: " released by the label",
  description10: " Nettwerk.",
  buttonLabel: "Song Writing",
  buttonLabel2: "Banners",
  imgStart: false,
  img: require("../../images/bannersImage.jpg"),
  alt: "Olly",
  dark: true,
  primary: true,
  darkText: false,
};
export const homeObjFour = {
  id: "musician",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Olly Gorman",
  headline: "Song writing",
  description: "Olly is also one third of the alt-pop band",
  description1: " Haarm",
  description2:
    ". Since the bands inception in 2014, the band have released over 10 singles and 4 e.ps. They gathered commercial success when their version of ",
  description3: "Everything Everything’s",
  description4: " song ",
  description5: "Believe it Now",
  description6: " was chosen for",
  description7: " BT Sports",
  description8:
    " 2018/19 Premier League Campaign. Haarm are soon to release their debut L.P.",
  buttonLabel: "Film & TV",
  buttonLabel2: "Haarm",
  imgStart: true,
  img: require("../../images/haarm.jpg"),
  alt: "Olly",
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjFive = {
  id: "FilmPage1",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "La Doc et Le Veto Episode 1",
  headline: "Starring: Michel Cymes and Dounia Coesens",
  description:
    "Co starring: Pasquale D'Inca, Valérie Schwarcz, Léo-Paul Salmain ",
  description2: "Director: Thierry Binisti",
  description3:
    "Screenplay: Sylviane Corgiat , Alysse Hallali and Bruno Lecigne",
  description4: "Director of photography: Michel Benjamin",
  description5: "Music: Olly Gorman",
  description6: "Production: Christian Gerin (executive producer)",
  description7: "Genre: Romantic comedy",
  description8: "Country: France",
  description9: "Broadcasting date : March 9, 2021 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/LadocetleVetoep1.jpg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjSix = {
  id: "FilmPage2",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "La Doc et Le Veto Episode 2",
  headline: "Starring: Michel Cymes and Dounia Coesens",
  description:
    "Co starring: Pasquale D'Inca, Valérie Schwarcz, Léo-Paul Salmain ",
  description2: "Director: Thierry Binisti",
  description3:
    "Screenplay: Sylviane Corgiat , Alysse Hallali and Bruno Lecigne",
  description4: "Director of photography: Michel Benjamin",
  description5: "Music: Olly Gorman",
  description6: "Production: Christian Gerin (executive producer)",
  description7: "Genre: Romantic comedy",
  description8: "Country: France",
  description9: " Broadcasting date : May 17, 2022 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/ladoc3.jpeg"),
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjSeven = {
  id: "FilmPage3",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "La Doc et Le Veto Episode 3",
  headline: "Starring: Michel Cymes and Dounia Coesens",
  description:
    "Co starring: Pasquale D'Inca, Valérie Schwarcz, Léo-Paul Salmain ",
  description2: "Director: Thierry Binisti",
  description3:
    "Screenplay: Sylviane Corgiat , Alysse Hallali and Bruno Lecigne",
  description4: "Director of photography: Michel Benjamin",
  description5: "Music: Olly Gorman",
  description6: "Production: Christian Gerin (executive producer)",
  description7: "Genre: Romantic comedy",
  description8: "Country: France",
  description9: "Broadcasting date : April 18, 2023 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/ladoc3.jpeg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjEight = {
  id: "FilmPage4",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "La Doc et Le Veto Episode 4",
  headline: "Starring: Michel Cymes and Dounia Coesens",
  description:
    "Co starring: Pasquale D'Inca, Valérie Schwarcz, Léo-Paul Salmain ",
  description2: "Director: Thierry Binisti",
  description3:
    "Screenplay: Sylviane Corgiat , Alysse Hallali and Bruno Lecigne",
  description4: "Director of photography: Michel Benjamin",
  description5: "Music: Olly Gorman",
  description6: "Production: Christian Gerin (executive producer)",
  description7: "Genre: Romantic comedy",
  description8: "Country: France",
  description9: "Broadcasting date : April 25, 2023 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/Ladocep4.jpeg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjNine = {
  id: "FilmPage5",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Meurtres en pays d'Oléron",
  headline: "Starring: Michel Cymes and Hélène Seuzaret",
  description:
    "Co starring: Murielle Huet des Aunay, Kalvin Winson, Ludmila Mikaël, Christian Caro, Vincent Deniard ",
  description2: "Director: Thierry Binisti",
  description3:
    "Screenplay: Jean-Marc Taba , Marc Antoine Laurent and Frédéric Faurt",
  description4: "Director of photography: Michel Benjamin",
  description5: "Music: Olly Gorman",
  description6: "Production: Christian Gerin (executive producer)",
  description7: "Genre: Crime Drama",
  description8: "Country: France",
  description9: "Broadcasting date : March 17, 2018 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/MeurtresenpaysdOleron.jpeg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjTen = {
  id: "FilmPage6",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Les Fantômes du Havre",
  headline: "Starring: Frédéric Diefenthal, Barbara Cabrita",

  description:
    "Co starring: Marie Bunel, Nicolas Married, Gilles Cohen, Antoine Duléry",
  description2: "Director: Thierry Binisti",
  description3: "Screenplay: Sylvain Saada",
  description4: "Director of photography: Christophe Paturange",
  description5: "Music: Olly Gorman",
  description6: "Production: Scarlett Production",
  description7: "Genre: Crime Drama",
  description8: "Country: France",
  description9: "Broadcasting date : December 22, 2018 on France 3",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/LesFantomesduHavre.jpg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
export const homeObjEleven = {
  id: "FilmPage7",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Hortense",
  headline: "Starring: Catherine Jacob Pauline Bression Christopher Bayemi",
  description: "Co starring:  ",
  description2: "Director: Thierry Binisti",
  description3: "Screenplay: Ingrid Desjours and Bruno Lecigne",
  description4: "Director of photography: Pierre Witzand",
  description5: "Music: Olly Gorman",
  description6: "Production: Chabraque Productions",
  description7: "Genre: Drama",
  description8: "Country: France",
  description9: "Broadcasting date : December 14, 2020 on France 2",
  buttonLabel: "Film & TV",
  buttonLabel2: "Show Reel",
  buttonLabel3: "Listen",
  imgStart: true,
  img: require("../../images/Hortense.jpg"),
  alt: "Olly",
  dark: false,
  primary: true,
  darkText: false,
};
